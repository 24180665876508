import {
  Box,
  CircularProgress,
  Container,
  CssBaseline,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Contacts, UnauthorizedHeader } from '../../../components/common';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useErrorHandler } from '../../../hooks/UseErrorHandler';
import { AuthService } from '../../../auth/auth.service';
import { useQuery } from 'react-query';
import { EntityQueryKey } from '../../../query/query.keys';
import { EmployeeService } from '../../../api/employee/employee.service';

export function EmployeeConfirm() {
  const [searchParams] = useSearchParams();
  const [token] = useState<string | null>(searchParams.get('token'));
  const [redirectUrl] = useState<string | null>(searchParams.get('redirectUrl'));
  const { t } = useTranslation('employee');
  const navigate = useNavigate();
  const errorHandler = useErrorHandler();
  const [messageKey, setMessageKey] = useState('');
  const user = AuthService.getUser();

  const { isLoading } = useQuery([EntityQueryKey.Employee, 'confirm'], () => EmployeeService.confirmEmployee(token), {
    onSuccess: (data) => {
      setMessageKey('message.success.confirmed');
      if (!redirectUrl) {
        if (!user || data !== user.uuid) {
          AuthService.signOut();
          // navigate(SignInRoute.SIGN_IN); пока у нас нет публичного фронта
          navigate('/');
        } else {
          navigate(EmployeeService.detailsUrl(data));
        }
      } else {
        window.location.replace(redirectUrl);
      }
    },
    onError: (err) => {
      console.log(err);
      errorHandler('confirm employee', err);
    },
  });

  return (
    <Container component='main' maxWidth='md'>
      <CssBaseline />
      <UnauthorizedHeader rightPadding='70px' />
      <Box sx={{ marginTop: 2, textAlign: 'center' }}>
        {isLoading && <CircularProgress />}
        {!isLoading && !!messageKey && <Typography variant='h5'>{t(messageKey)}</Typography>}
      </Box>
      <Contacts />
    </Container>
  );
}
