import { Navigate, Route, Routes } from 'react-router-dom';
import { AdminLogin } from '../../admin';
import React from 'react';
import { SignInRoute } from '../../signIn/routes';
import { AdminRoute } from '../../admin/routes';
import { SignOutRoute } from '../../signOut/routes';
import { SignOut } from '../../signOut/SignOut';
import { RestoreRoute } from '../../restore/routes';
import { ForgotPassword } from '../../restore/ForgotPassword';
import { ACCOUNT_ROUTES, EMPLOYEE_ROUTES, USER_ROUTES } from '../index';
import { ChangePassword } from '../../user/ChangePassword';
import { ConfirmUserSettings } from '../../account/ConfirmUserSettings';
import { ConfirmEmail } from '../../account/ConfirmEmail';
import { Main } from '../../Main';
import { ApiKey } from '../../account/ApiKey';
import { EmployeeConfirm } from '../../employee/EmployeeConfirm';

export function UnauthorizedRoutes() {
  return (
    <Routes>
      <Route path='/' element={<Main />} />
      <Route path={SignInRoute.SIGN_IN} element={<AdminLogin />} />
      <Route path={AdminRoute.SIGN_IN} element={<AdminLogin />} />
      <Route path={SignOutRoute.SIGN_OUT} element={<SignOut />} />
      <Route path={RestoreRoute.FORGOT_PASSWORD} element={<ForgotPassword />} />
      <Route path={USER_ROUTES.changePassword} element={<ChangePassword />} />
      <Route path={ACCOUNT_ROUTES.confirmUserSettings} element={<ConfirmUserSettings />} />
      <Route path={ACCOUNT_ROUTES.confirmEmail} element={<ConfirmEmail />} />
      <Route path={ACCOUNT_ROUTES.apiKey} element={<ApiKey />} />
      <Route path={EMPLOYEE_ROUTES.confirm} element={<EmployeeConfirm/>} />
      <Route path='*' element={<Navigate to='/' replace={true} />} />
    </Routes>
  );
}
