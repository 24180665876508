import { useNavigate, useSearchParams } from 'react-router-dom';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useErrorHandler } from '../../../hooks/UseErrorHandler';
import { useQuery } from 'react-query';
import { EntityQueryKey } from '../../../query/query.keys';
import { UserService } from '../../../api/user/user.service';
import { Box, CircularProgress, Container, CssBaseline, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import InputControl from '../../../components/common/ui/InputControl';
import { CopyAllOutlined } from '@mui/icons-material';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useSnackbar } from 'notistack';
import { AuthService } from '../../../auth/auth.service';
import { Contacts, UnauthorizedHeader } from '../../../components/common';

export function ConfirmEmail() {
    const [searchParams] = useSearchParams();
    const [token] = useState<string | null>(searchParams.get('token'));
    const [isApi] = useState<boolean | null>(searchParams.get('isApi') === 'true');
    const { t } = useTranslation('account', { keyPrefix: 'confirmEmail' });
    const navigate = useNavigate();
    const errorHandler = useErrorHandler();
    const [messageKey, setMessageKey] = useState('');
    const [apiKey, setApiKey] = useState('');
    const { enqueueSnackbar } = useSnackbar();
    const user = AuthService.getUser();

    const { isLoading } = useQuery([EntityQueryKey.User, 'confirmEmail'], () => UserService.confirmEmail(token, isApi), {
        onSuccess: (data) => {
            setMessageKey('confirmed');
            if (isApi && data.apiKey) {
                setApiKey(data.apiKey);
            } else {
                if (!user || data.user !== user?.uuid) {
                    AuthService.signOut();
                    // navigate(SignInRoute.SIGN_IN); пока у нас нет публичного фронта
                    navigate('/');
                } else {
                    navigate(UserService.detailsUrl(data.user));
                }
            }
        },
        onError: (err) => {
            console.log(err);
            errorHandler('confirm email', err);
        },
    });

    return (
        <Container component="main" maxWidth="md">
            <CssBaseline />
            <UnauthorizedHeader rightPadding="70px"/>
            <Box sx={{ marginTop: 2, textAlign: 'center' }}>
                {isLoading && <CircularProgress />}
                {!isLoading && !!messageKey && <Stack direction="column">
                  <Typography variant="h5">{t(messageKey)}</Typography>
                    {isApi && <Stack direction="row" alignItems="start">
                      <InputControl labelKey="account:confirmEmail.apiKey" value={apiKey} onChange={() => false} disabled type="textarea" multiline/>
                      <CopyToClipboard text={apiKey} onCopy={() => { enqueueSnackbar({ message: t('copied'), variant: 'success' }) }}>
                        <Tooltip title={t('copyHint')}>
                          <IconButton color="primary" sx={{ mt: 1, ml: 1 }}>
                            <CopyAllOutlined/>
                          </IconButton>
                        </Tooltip>
                      </CopyToClipboard>
                    </Stack>}
                </Stack>}
            </Box>
            <Contacts rightPadding="60px"/>
        </Container>
    )
}