import { CrudService } from '../crud.service';
import { EMPLOYEE_ROUTES } from '../../page/route';
import { AuthService } from '../../auth/auth.service';
import axios from 'axios';
import { API } from '../index';
import { API_URL } from '../../model/constants';

class CEmployeeService extends CrudService {
  constructor() {
    super('v1/employee', EMPLOYEE_ROUTES);
  }

  async confirmEmployee(confirmationToken: string | null): Promise<string> {
    const httpClient = AuthService.getAuth() == null ? axios : API;
    return (await httpClient.post(`${API_URL}${this.baseUrl}/confirm`, { confirmationToken })).data;
  }
}

export const EmployeeService = new CEmployeeService()