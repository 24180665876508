import { EntityRoutes } from './entity.routes';

export class EmployeeRoutes extends EntityRoutes {
  constructor() {
    super('employee', 'uuid');
  }

  get confirm() {
    return `${this.base}/confirm`;
  }
}